$(function () {
    // ajax handler
    /*const send = function (url, method = 'POST', payload, json = true, api = 0) {
        payload.append('api', api);
        return fetch(document.location.href, {
            method: 'POST',
            body: payload,
        }).then(resp => (json) ? resp.json() : resp.text());
    }*/
    const productsContainer = document.querySelector('.page--category #content');
    const h = window.history;
    // event listeners
    if (productsContainer) {
        setResetBtnForCheckedFilters();
        document.addEventListener('click', function (e) {


        }, true);
        document.addEventListener('submit', function (e) {
            if (e.target.id === 'filter_form') {
                e.preventDefault();

                let data = new FormData(e.target);


                // remove default values;
                let keysToRemove = []; // remove keys in loop data.entries() not working correctly;
                for (let [key, value] of data.entries()) {
                    console.log(key, value);
                    if (key.indexOf('price') !== -1) {
                        let el = e.target.querySelector('[name="' + key + '"]')
                        let keyArr = key.split('_');
                        let sliderKey = keyArr[1] === 'min' ? 'sliderMinValue' : 'sliderMaxValue';

                        if (el.value === el.dataset[sliderKey]) {
                            keysToRemove.push(key);
                        }
                    }
                }
                let url = buildQueryString(data, keysToRemove);

                productsContainer.classList.add('processing');
                fetch(url.fetch)
                    .then(data => data.text())
                    .then(resp => {
                        if (h.pushState) {
                            h.pushState({
                                category: url.history
                            }, document.title, url.history);
                        }
                        productsContainer.innerHTML = resp;

                    }).finally(function () {
                    productsContainer.classList.remove('processing');
                })
                /*send(data, 'POST',, 1).then(resp => {
                    if (resp.success) {
                        productsContainer.innerHTML = resp.html;
                        if (resp.q) {
                            if (h.pushState) {
                                h.pushState({
                                    category: url
                                }, document.title, url);
                            }
                        }
                    }
                }).finally(function () {
                    productsContainer.classList.remove('processing');
                });*/
            }
        }, true);
        document.addEventListener('change', function (e) {
            if(e.target.type === 'checkbox'){
                setResetBtnForCheckedFilters();
            }
        }, true);
        // PRICE SLIDER
        let filterPriceSlider = document.getElementById('filterPriceSlider')
        if (filterPriceSlider) {
            let sliderReset = filterPriceSlider.parentElement.parentElement.querySelector('.reset')
            let priceMin = document.getElementById('filterPriceMin'),
                priceMax = document.getElementById('filterPriceMax')
            let values = {
                sliderMin: parseInt(priceMin.dataset.sliderMinValue),
                sliderMax: parseInt(priceMax.dataset.sliderMaxValue),
                min: parseInt(priceMin.value),
                max: parseInt(priceMax.value)
            }
            noUiSlider.create(filterPriceSlider, {
                start: [values.sliderMin, values.sliderMax],
                connect: true,
                range: {
                    'min': [values.sliderMin],
                    'max': [values.sliderMax]
                },
                format: {
                    to: function (value) {
                        return parseInt(value)
                    },
                    from: function (value) {
                        return (value)
                    }
                }
            });
            filterPriceSlider.noUiSlider.set([values.min, values.max])
            priceMin.onchange = function (e) {
                filterPriceSlider.noUiSlider.set([e.target.value, null])
            }
            priceMax.onchange = function (e) {
                filterPriceSlider.noUiSlider.set([null, e.target.value])
            }
            filterPriceSlider.noUiSlider.on('update', function (value, handle) {
                console.log(handle)
                priceMin.value = value[0]
                priceMax.value = value[1]
                if (priceMin.dataset.sliderMinValue === priceMin.value && priceMax.dataset.sliderMaxValue === priceMax.value) {
                    sliderReset.style.display = ''
                } else {
                    sliderReset.style.display = 'block';
                }

            })
            filterPriceSlider.noUiSlider.on('set', function () {


            })
        }


        $('.filter__heading').on('click', function (e) {
            if (!e.target.classList.contains('reset')) {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active')
                    $(this).siblings('.filter__body').slideUp(200)
                } else {
                    $(this).addClass('active')
                    $(this).siblings('.filter__body').slideDown(200)
                }
            }
        })


        if (window.outerWidth > 768) {
            $('#input-sort').on('change', function () {
                $('#filterBtnApply').trigger('click')
            })
        }

        $('.filter .reset').on('click', function () {
            if ($(this).data('slider')) {
                filterPriceSlider.noUiSlider.reset()
            } else {
                $(this).closest('.filter').find('input[type="checkbox"]').prop('checked', false)
                $(this).hide()
            }
            $('#filterBtnApply').trigger('click');

        })
        $('#filterBtnApply').on('click', function () {
            if (window.outerWidth <= 768) {
                $('.category-filter').removeClass('active')
                $('body').removeClass('mobile-hidden')
            }
        })
        $('#filterBtnReset').on('click', function () {
            $('#filter_form input[type="checkbox"]').prop('checked', false)
            filterPriceSlider.noUiSlider.reset();
            $('#filterBtnApply').trigger('click');
        })

        /*function filter(page = 0) {
            let filter = {},
                sorter = '',
                checkboxes = [],
                content = document.getElementById("content"),
                priceMin = document.getElementById('filterPriceMin'),
                priceMax = document.getElementById('filterPriceMax')

            $('input[type="checkbox"]:checked').each(function (i, v) {
                checkboxes.push(v.value);
            });
            if (checkboxes.length > 0) {
                filter.checkbox = 'filter=' + checkboxes.join(',')
            }
            sorter = $('#input-sort').val()
            if (sorter === 'sort=p.sort_order&order=ASC') {
                sorter = ''
            }
            filter.sort = sorter
            if (priceMin.value !== priceMin.dataset.sliderMinValue) {
                filter.priceMin = 'price_min=' + priceMin.value
            } else {
                filter.priceMin = ''
            }
            if (priceMax.value !== priceMax.dataset.sliderMaxValue) {
                filter.priceMax = 'price_max=' + priceMax.value
            } else {
                filter.priceMax = ''
            }
            let query = '',
                base = document.location.href.split('?')[0],
                url_query = ''
            $.each(filter, function (i, v) {
                if (v) {
                    query += '&' + v
                    if (url_query) {
                        url_query += '&' + v
                    } else {
                        url_query += v
                    }
                }
            })
            let query_parameters = document.location.search.substring(1).split('&')
            let queried_page = ''
            query_parameters.forEach(el => {
                if (el.indexOf('page') > -1) {
                    queried_page = el
                }
            })

            if (page > 0) {
                query += '&page=' + page
                if (url_query) {
                    url_query += '&page=' + page
                } else {
                    url_query += 'page=' + page
                }
            } else if (queried_page) {
                query += '&' + queried_page
                if (url_query) {
                    url_query += '&' + queried_page
                } else {
                    url_query += queried_page
                }
            }

            let h = window.history,
                url = url_query ? base + '?' + url_query : base


        }*/


        $('.flower_cat_sidebar__heading').on('click', function () {
            $('.flower_cat_list').slideToggle(200)
        })

        function buildQueryString2(params, defaults) {
            let query = new URLSearchParams(document.location.search),
                base = document.location.origin + document.location.pathname;

            // remove filters
            query.delete('price_min');
            query.delete('price_max');
            query.delete('flowers[]');
            query.delete('color[]');
            if (params instanceof FormData) {
                for (let [key, value] of params.entries()) {
                    query.append(key, value);
                }
            } else {
                for (let [key, value] of Object.entries(params)) {
                    query.append(key, value);
                }
            }
            defaults.forEach(key => {
                query.delete(key);
            });

            let queryString = query.toString();
            return {
                history: base + (queryString ? '?' + queryString : ''),
                fetch: base + '?api=1' + (queryString ? '&' + queryString : '')
            }
        }

        function buildQueryString(params, defaults = []) {
            let query = new URLSearchParams(document.location.search),
                base = document.location.origin + document.location.pathname;

            // remove all filters from URL
            document.querySelectorAll('#filter_form input').forEach(input => {
                query.delete(input.name);
            })


            let preparedParams = {};
            let paramsIterator = (params instanceof FormData) ? params.entries() : Object.entries(params);
            for (let [key, value] of paramsIterator) {
                if (!preparedParams[key]) {
                    preparedParams[key] = [];
                }
                query.delete(key);
                preparedParams[key].push(value);
            }
            for (let [key, value] of Object.entries(preparedParams)) {
                query.append(key, value.join(','));
            }
            if (defaults.length > 0) {
                defaults.forEach(key => {
                    query.delete(key);
                });
            }
            defaults.forEach(key => {
                query.delete(key);
            });

            let queryString = query.toString();
            return {
                history: base + (queryString ? '?' + queryString : ''),
                fetch: base + '?api=1' + (queryString ? '&' + queryString : '')
            }
        }

        function setResetBtnForCheckedFilters(el){
            $('.filter').each(function (i, v) {
                if ($(v).find('input[type="checkbox"]:checked').length > 0) {
                    $(v).find('.reset').show()
                } else {
                    $(v).find('.reset').hide()
                }

            })
        }

        function customThrottle(func, wait, options) {
            var context, args, result;
            var timeout = null;
            var previous = 0;
            if (!options) options = {};
            var later = function () {
                previous = options.leading === false ? 0 : Date.now();
                timeout = null;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            };
            return function () {
                var now = Date.now();
                if (!previous && options.leading === false) previous = now;
                var remaining = wait - (now - previous);
                context = this;
                args = arguments;
                if (remaining <= 0 || remaining > wait) {
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = null;
                    }
                    previous = now;
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                } else if (!timeout && options.trailing !== false) {
                    timeout = setTimeout(later, remaining);
                }
                return result;
            };
        };
    }
})
